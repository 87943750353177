.location-input:focus {
    outline: none !important;
    border: 1px solid black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.description-input textarea {
    outline: none !important;
    border: 1px solid rgb(209 213 219);
    border-radius: 0.375rem;
}

.description-input textarea:focus {
    outline: none !important;
    border: 1px solid black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}